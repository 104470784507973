import React from 'react'
import styled from 'styled-components'
import 'twin.macro'

const StyledDiv = styled.div`
    width:100%;
    margin: 0 auto;
    background: #D4DEC0;
    padding-top: 20px;
    padding-bottom: 70px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0px 12px 6px rgba(0, 0, 0, 0.23);
    .description-class{
        max-width: 540px;
        margin: 0 auto;
    }
`

export default function HeroBanner({data,page}) {

    let finalData= data?.schema_json;  
    let content= {
       ...finalData
  }  

    return (
        <StyledDiv>
            <div tw="flex flex-col items-center text-center mt-4 mx-16 md:mx-56">
                <div tw="text-4xl mb-8">
                    <span tw="font-bold"  dangerouslySetInnerHTML={{ __html: content.heading }}></span>
                </div>
                <div tw="m-6 md:m-8" className="description-class">
                    <p>{content.description}</p>
                </div>
                <div tw="flex justify-center items-center mb-4 mt-8">
                    <button tw="text-white p-3  text-xs font-bold text-center px-8" className="cartButton" style={{background:`${content.button_background_color}` }}>{content.button_text}</button>
                </div> 
            </div>
        </StyledDiv>
    )
}

